<template>
  <div v-if="reservation">
    <ui-component-drawer
      v-if="showSideDrawer"
      :showDrawer="showSideDrawer"
      :drawerTitle="drawerTitle"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <SpaceSummary
          v-if="showSpaceSummary"
          :spaceId="selectedSpace.SpaceId"
          :meetingtypeId="reservation.MeetingtypeId"
        />
      </template>
    </ui-component-drawer>

    <ModalAddSpace
      v-if="showAddSpace"
      :showModal="showAddSpace"
      :channelId="reservation.ChannelId"
      :locationId="reservation.LocationId"
      :startDate="new Date(reservation.StartDate)"
      :startMinutes="resStart"
      :endDate="new Date(reservation.StartDate)"
      :endMinutes="resEnd"
      :seats="Number(reservation.TotalSeats)"
      :voucherId="Number(reservation.VoucherId)"
      :onClickCancel="hideModal"
    />

    <ModalEditSpace
      v-if="showEditSpace"
      :showModal="showEditSpace"
      :onClickCancel="hideModal"
      :channelId="reservation.ChannelId"
      :isEmployee="isEmployee"
      :locationId="reservation.LocationId"
      :meetingtypeId="reservation.MeetingtypeId"
      :space="selectedSpace"
      :index="selectedSpace.Index"
      @spaceEdited="spaceEdited"
    />

    <ModalDeleteSpace
      v-if="showDeleteSpace"
      :showModal="showDeleteSpace"
      :space="selectedSpace"
      :onClickCancel="hideModal"
    />

    <ModalEditTotalSeats
      v-if="showEditTotalSeats"
      :showModal="showEditTotalSeats"
      :isEmployee="isEmployee"
      :isLocked="isLocked"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showAdd="!isLocked && reservation.MeetingtypeId === 1"
      :addFunction="setShowAddSpace"
      :addLabel="'Add space'"
    >
      <template v-slot:title>{{
        $t('Components.Reservation.ReservationMeetingspace.Subtitle_Spaces')
      }}</template>
    </ui-section-header>

    <Message
      v-if="reservation.Spaces.length === 0"
      :message="'No spaces added!'"
    />

    <div v-if="reservation.Spaces.length > 0">
      <table class="table is-fullwidth is-striped is-hoverable is-narrow">
        <thead>
          <tr>
            <th width="100" class="has-text-left">Seats</th>
            <th>Space</th>
            <th width="175">Configuration</th>
            <th width="100">Time</th>
            <th width="75" class="has-text-right">Price</th>
            <th class="has-text-right" width="75">VAT</th>
            <th width="100" class="has-text-right">Total</th>
            <th width="85" class="is-hidden-print">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(meetingspace, index) in reservation.Spaces"
            :key="'sId' + meetingspace.Id + '_' + index"
          >
            <td class="has-text-left">{{ meetingspace.Seats }}x</td>
            <td>
              <span>{{ meetingspace.SpaceName }}</span>
              <a @click="setShowSpaceSummary(meetingspace)">
                <span class="icon is-small has-margin-left is-hidden-print">
                  <font-awesome-icon :icon="['fas', 'info-circle']" />
                </span>
              </a>

              <div v-if="meetingspace.Id > 0">
                <Notes
                  :locationId="reservation.LocationId"
                  :typeId="'ReservationSpace'"
                  :itemId="meetingspace.Id"
                  :notes="meetingspace.Notes"
                  :showNoNotesMessage="false"
                  :showAddLinkLeft="true"
                  :linkTypeId="'Reservation'"
                  :linkItemId="reservation.Id"
                  :getFresh="false"
                  :viewType="'small'"
                />
              </div>
            </td>
            <td v-if="reservation.MeetingtypeId === 1">
              {{ meetingspace.SettingId | getSettingName }}
            </td>
            <td>
              {{ meetingspace.StartMinutes | minutesToTime }} -
              {{ meetingspace.EndMinutes | minutesToTime }}
            </td>
            <td class="has-text-right">
              <span v-if="!meetingspace.IsPackage">{{
                meetingspace.PricePerSeat
                  | toCurrency('en', meetingspace.CurrencyIso)
              }}</span>
            </td>
            <td class="has-text-right">
              <span
                v-if="!meetingspace.IsPackage"
                :class="{
                  'has-text-danger has-text-weight-bold':
                    meetingspace.TaxPercentage === 0,
                }"
                >{{ meetingspace.TaxPercentage }}%</span
              >
            </td>
            <td class="has-text-right">
              <span v-if="!meetingspace.IsPackage">{{
                meetingspace.PriceTotal
                  | toCurrency('en', meetingspace.CurrencyIso)
              }}</span>
            </td>
            <td width="75" class="is-hidden-print">
              <div class="field is-grouped  is-grouped-right">
                <p class="control">
                  <a
                    v-if="reservation.MeetingtypeId === 1"
                    @click="setSpaceLock(meetingspace, index)"
                    :style="{ overflow: 'visible' }"
                    class="has-tooltip-arrow has-tooltip-multiline has-tooltip-left"
                    :data-tooltip="
                      meetingspace.IsLocked ? 'Unlock space' : 'Lock space'
                    "
                  >
                    <span class="icon">
                      <font-awesome-icon
                        :icon="[
                          'fas',
                          meetingspace.IsLocked ? 'lock' : 'lock-open',
                        ]"
                      />
                    </span>
                  </a>
                </p>

                <p v-if="!isLocked" class="control">
                  <a @click="setShowEditSpace(meetingspace, index)">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>
                </p>

                <p
                  v-if="!isLocked && reservation.Spaces.length !== 1"
                  class="control"
                >
                  <a @click="setShowDeleteSpace(meetingspace, index)">
                    <span class="icon has-text-danger">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              :colspan="reservation.MeetingtypeId === 1 ? 6 : 5"
              class="has-text-right"
            >
              Sub total
            </td>
            <td class="has-text-right">
              <span>{{
                total | toCurrency('en', reservation.CurrencyIso)
              }}</span>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'

const Message = () => import('@/components/UI/Message')
const ModalAddSpace = () => import('@/components/Reservations/ModalAddSpace')
const ModalEditSpace = () => import('@/components/Reservations/ModalEditSpace')
const ModalEditTotalSeats = () =>
  import('@/components/Reservations/ModalEditTotalSeats')
const ModalDeleteSpace = () =>
  import('@/components/Reservations/ModalDeleteSpace')
const Notes = () => import('@/components/Notes/Notes')
const SpaceSummary = () => import('@/components/Spaces/SpaceSummary')

export default {
  components: {
    Message,
    ModalAddSpace,
    ModalEditSpace,
    ModalEditTotalSeats,
    ModalDeleteSpace,
    Notes,
    SpaceSummary,
  },

  props: {
    isEmployee: {
      default: false,
      type: Boolean,
    },

    isLocked: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      currentTotalSeats: 0,
      drawerTitle: '',
      selectedSpace: null,
      showAddSpace: false,
      showEditSpace: false,
      showEditTotalSeats: false,
      showDeleteSpace: false,
      showSideDrawer: false,
      showSpaceSummary: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    reservationHasPackageApplied() {
      let result = false

      if (this.reservation && this.reservation.Voucher) {
        result = this.reservation.Voucher.Type === 'Package'
      }

      return result
    },

    resStart() {
      let minStartMinutes = 0
      if (this.reservation && this.reservation.Spaces.length > 0) {
        var lowest = Number.POSITIVE_INFINITY
        var tmp
        for (var i = this.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = this.reservation.Spaces[i].StartMinutes
          if (tmp < lowest) {
            lowest = tmp
          }
        }
        minStartMinutes = lowest
      }
      return minStartMinutes
    },

    resEnd() {
      let maxEndMinutes = 0
      if (this.reservation && this.reservation.Spaces.length > 0) {
        var highest = Number.NEGATIVE_INFINITY
        var tmp
        for (var i = this.reservation.Spaces.length - 1; i >= 0; i--) {
          tmp = this.reservation.Spaces[i].EndMinutes
          if (tmp > highest) {
            highest = tmp
          }
        }
        maxEndMinutes = highest
      }
      return maxEndMinutes
    },

    spacesTotalSeats() {
      let total = 0

      if (this.reservation && this.reservation.Spaces.length > 0) {
        for (let i = 0; i < this.reservation.Spaces.length; i++) {
          let space = this.reservation.Spaces[i]

          total += space.Seats
        }
      }

      return total
    },

    total() {
      let total = 0.0

      if (this.reservation.Spaces.length > 0) {
        for (let i = 0; i <= this.reservation.Spaces.length; i++) {
          let meetingspace = this.reservation.Spaces[i]

          if (meetingspace) {
            if (!this.reservationHasPackageApplied || !meetingspace.InVoucher) {
              total = total + meetingspace.PriceTotal
            }
          }
        }
      }

      return total
    },
  },

  created() {
    this.currentTotalSeats = this.reservation.TotalSeats
  },

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    minutesToTime(minutes) {
      let hour = Number(Math.floor(minutes / 60))
      let minute = Number(minutes - hour * 60)

      return (
        (hour < 10 ? '0' : '') + hour + ':' + (minute < 10 ? '0' : '') + minute
      )
    },

    setSpaceLock(space) {
      if (!space.IsLocked) {
        reservationProvider.methods
          .lockReservationSpace(this.reservation.Id, space.Id)
          .then((response) => {
            if (response.status === 200) {
              this.setReservation(response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Space locked',
              })
            }
          })
      } else {
        reservationProvider.methods
          .unlockReservationSpace(this.reservation.Id, space.Id)
          .then((response) => {
            if (response.status === 200) {
              this.setReservation(response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Space unlocked',
              })
            }
          })
      }
    },

    spaceEdited() {
      if (this.currentTotalSeats !== this.spacesTotalSeats) {
        this.setShowEditTotalSeats()
      }

      this.currentTotalSeats = this.spacesTotalSeats
    },

    setShowSpaceSummary(space) {
      this.drawerTitle = 'Space details'
      this.showSideDrawer = true
      this.showSpaceSummary = true

      this.selectedSpace = JSON.parse(JSON.stringify(space))
    },

    setShowAddSpace() {
      this.showAddSpace = true
    },

    setShowEditSpace(space, index) {
      this.selectedSpace = JSON.parse(JSON.stringify(space))
      this.selectedSpace.Index = index

      this.showEditSpace = true
    },

    setShowDeleteSpace(space, index) {
      this.selectedSpace = JSON.parse(JSON.stringify(space))
      this.selectedSpace.Index = index

      this.showDeleteSpace = true
    },

    setShowEditTotalSeats() {
      this.showEditTotalSeats = true
    },

    hideModal() {
      this.showAddSpace = false
      this.showEditSpace = false
      this.showEditTotalSeats = false
      this.showDeleteSpace = false

      this.selectedSpace = null
    },

    hideDrawer() {
      this.drawerTitle = ''
      this.selectedSpace = null
      this.showSpaceSummary = false
      this.showSideDrawer = false
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-space {
  backface-visibility: hidden;
}

.zoom-fade-enter,
.zoom-fade-leave-active {
  opacity: 0;
  //transform: scale(0);
}

.zoom-fade-enter-active,
.zoom-fade-leave-active {
  transition: opacity 0.5s ease;
  //transform: scale(1) 1.5s ease;
}
</style>
